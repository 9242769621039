import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delayMS: { type: Number, default: 2000 },
  }
  static targets = [
    "click"
  ]

  onTimeout() {
    if (this.hasClickTarget) {
      this.clickTarget.click()
    }
  }
  
  connect() {
    if (this.delayMSValue) {
      if (this.timerId) { clearTimeout(this.timerId) }
      this.timerId = setTimeout(this.onTimeout(), this.delayMSValue)
    }
  }

  disconnect() {
    if (this.timerId) { clearTimeout(this.timerId) }
  }
}

