import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        "cancel": String, // cancel button id
        "bucket": String,
        "area": String,
        "project": String
    }

    connect() {
        this.isConnected = true;
        this.updateForm();

        document.addEventListener("turbo:submit-end", this.updateForm.bind(this));
    }

    disconnect() {
        document.removeEventListener("turbo:submit-end", this.updateForm.bind(this));
        this.isConnected = false;
    }

    cancel(event) {
        event.preventDefault();
        this.element.classList.add('hidden');
    }

    updateForm() {
        if (!this.isConnected) {
            return;
        }

        if (this.element.parentElement) {
            // give the form time to render
            setTimeout(() => {
                this.setupForm();
            }, 100);
        }
    }

    setupForm() {
        if (!this.isConnected) {
            return;
        }
        const cancelButton = document.getElementById(this.cancelValue);
        if (cancelButton) {
            cancelButton.addEventListener("click", this.cancel.bind(this));
        } else {
            console.error("Cancel button not found");
        }

        if (this.bucketValue && this.bucketValue !== "") {
            const taskBucket = document.getElementById('task_bucket');
            if (taskBucket) {
                taskBucket.value = this.bucketValue;
            } else {
                console.error("task bucket not found");
            }
        }

        if (this.areaValue && this.areaValue !== "") {
            const taskArea = document.getElementById('task_area');
            if (taskArea) {
                taskArea.value = this.areaValue;
            } else {
                console.error("task area not found");
            }
        }

        if (this.projectValue && this.projectValue !== "") {
            const taskProject = document.getElementById('task_project');
            if (taskProject) {
                taskProject.value = this.projectValue;
            } else {
                console.error("task project not found");
            }
        }
    }
}
