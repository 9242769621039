import { Controller } from "@hotwired/stimulus"

// This controller is used to copy the value of an input element or a link to another input element.
//
// This can be useful when you have a page that cannot have overlapping forms
// you can then place an element outside of the form and copy the value to the form input.
// target = the id of the input element to copy the value to
// You also need to make sure you add the data-action="input->input-copy#copy" to the input element you want to copy
// Example:
// <input type="text" id="input1" data-controller="input-copy" data-action="input-copy#copy" data-input-copy-target="input2">
//
// You can also use this controller to copy the value of a link to an input element.
// Example:
// <a href="#" id="link1" data-controller="input-copy" data-action="click->input-copy#copy" data-input-copy-target-value="input2">Copy</a>
export default class extends Controller {
    static values = {
        target: String
    }

    copy() {
        console.log("Copying value to target")
        const target = document.getElementById(this.targetValue)

        if (!target) {
            console.error("InputCopy: missing target value")
            return
        }

        if (this.element.tagName === "A") {
            target.value = this.element.innerHTML
            return
        }
        target.value = this.element.value
    }
}