import Sortable from 'stimulus-sortable'
import { patch }  from "@rails/request.js"

export default class extends Sortable {

    get defaultOptions() {
        return {
            forceFallback: true // needed to get ThirdPartyDraggable to work in FullCalendar
        }
    }

    async onUpdate(event) {
        const itemId = event.item.getAttribute('data-id')

        let paramValue

        if (event.newIndex === 0) {
            // find the next element
            const nextElement = event.to.children[event.newIndex + 1]

            if (nextElement) {
                const nextElementId = nextElement.getAttribute('data-id')
                // we will come before this element
                paramValue = `before:${nextElementId}`
            } else {
                console.warn('No next element! This is an empty list. We are already first, so nothing to do!');
            }
        } else {
            // find the previous element
            const prevElement = event.to.children[event.newIndex - 1]
            if (prevElement) {
                const prevElementId = prevElement.getAttribute('data-id')
                // we will come after this
                paramValue = `after:${prevElementId}`
            } else {
                console.warn('No previous element! This is unexpected since we already checked if we have a newIndex>0');
            }
        }

        if (!paramValue) { return }

        // super.onUpdate(event)
        // we actually override this, since we want to add additional params
        // https://github.com/stimulus-components/stimulus-sortable/blob/master/src/index.ts#L44
        if (!event.item.dataset.sortableUpdateUrl) return

        const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

        const data = new FormData()
        data.append(param, paramValue)

        return await patch(event.item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
    }
}
