import {Controller} from "@hotwired/stimulus"

// Allows the user to easily submit a form by pressing cmd+enter or ctrl+enter or esc
// Example usage:
// form_with url: quick_add_path, class: "w-full h-full flex flex-col",
// data: { controller: "easy-submit",
// action: "keydown.esc->easy-submit#cancel keydown.meta+enter->easy-submit#submit keydown.ctrl+enter->easy-submit#submit" } do |form| %>

export default class extends Controller {
    static values = {
        disableCancel: Boolean
    }

    static targets = [
        "submit",
        "cancel",
        "other"]
    // submit is optional, if not specified, the controller will:
    // 1. submit the form it is defined in (if it is defined in a form)
    // 2. submit the form the input field is in (if it is defined in an input field)
    //
    // cancel is optional, if not specified, the controller will:
    // 1. if the target is specified it clicks this
    // 2. if there is a link or button with the class "cancel" or text "cancel" it clicks this
    // 3. Otherwise it just returns to the root

    connect() {
        // add keydown listener to this element
        this.element.addEventListener('keydown', this.handleKeydown.bind(this))

        // add keydown listener to any input text or textareas that are children of this element
        this.element.querySelectorAll('input[type="text"], textarea').forEach(input => {
            input.addEventListener('keydown', this.handleKeydown.bind(this))
        })
    }

    handleKeydown(event) {
        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
            event.stopPropagation()
            this.submit()
        }
        if (event.key === 'Escape') {
            event.stopPropagation()
            this.cancel()
        }
    }

    submit() {
        if (this.hasSubmitTarget) {
            this.submitTarget.click()
        } else if (this.element.tagName === 'FORM') {
            this.element.requestSubmit()
        } else if (this.element.tagName === 'INPUT' && this.element.form) {
            this.element.form.requestSubmit()
        } else {
            alert("Sorry, I don't know how to submit this form.")
        }
    }

    cancel() {
        if (this.disableCancelValue) {
            return
        }

        if (this.hasCancelTarget) {
            this.cancelTarget.click()
        } else {
            // is there a link or button with class cancel or text of cancel
            let foundCancelElement = false
            this.element.querySelectorAll('a, button').forEach(input => {
                if (input.classList.contains('cancel') || input.textContent.toLowerCase().includes('cancel')) {
                    input.click()
                    foundCancelElement = true
                }
            })
            // otherwise just go back to the root
            if (!foundCancelElement) {
                Turbo.visit('/')
            }
        }
    }

    other() {
        if (this.otherTarget) {
            this.otherTarget.click()
        } else {
            alert('No other target found')
        }
    }
}
